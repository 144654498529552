.sideNav {
    width: $sideNavWidth;
    #{$sideNavDirection}: -$sideNavWidth;
    position: fixed;
    z-index: 1002;
    height: 100%;
    top: 0;
    background: $offwhite;
    border-left: solid 3px $brand-primary;
    transition: all 0.3s ease;
    overflow-y: scroll;
    a {
        display: block;
        color: $black;
        font-size: 19px;
        padding: 10px 15px;
    }

    &.sideNav-open {
        #{$sideNavDirection}: 0px;
    }
    .menu-item-31 {
      display: none;
    }
    ul.nav.flex-column {
      width: 100%;
    }
    li.menu-item-has-children {
      ul.sub-menu {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        padding-left: 15px;
        // background-color: #fff;
        list-style-type: none;
        transition: 0.3s;
        height: auto;
        &.open {
          position: relative;
          visibility: visible;
          opacity: 1;
        }
      }
    }
    li.menu-item-has-children>a:after {
      margin-left: 5px;
      content: "\f0d7";
      font-family: FontAwesome;
      font-size: 14px;
    }
}

/* classes applied to the body */
.sideNavBody {
    overflow-x: hidden;
    position: relative;
    #{$sideNavDirection}: 0;
    transition: all 0.3s ease;
}

.sideNavBody-push {
    #{$sideNavDirection}: $sideNavWidth;
}
