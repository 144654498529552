#skip-navigation {
  position: absolute;
  display: block;
  background: $white;
  color: $brand-primary;
  padding: 15px;
  text-align: center;
  transform: translateY(-100%);
  transition: transform 0.25s ease-in-out;
  z-index: 1000;

  &:focus {
    transform: translateY(0);
    transition: transform 0.25s ease-in-out;
  }
}

#modal {
  overflow: scroll;
  background-color: rgba($black, 0.5);

  .modal-title {
    color: $brand-primary;
    font-family: $secondary-font;
  }

  .modal-dialog {
    @include tablet {
      max-width: 75%;
    }

    @include hd {
      max-width: 50%;
    }
  }

  .btn-secondary {
    background-color: $brand-primary;
    border-radius: 0;

    &:hover {
      background-color: darken($brand-primary, 10%);
    }
  }
}

body {
  padding-top: 60px;

  a.call-now {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    top: 0;
    color: #fff;
    background-color: $brand-secondary;
    font-weight: bold;
    font-size: 22px;
    height: 60px;
    text-align: center;
    z-index: 1001;

    i.fa-phone {
      margin-right: 7px;
      margin-top: 5px;
    }
  }

  @include desktop {
    padding-top: 0;

    a.call-now {
      display: none;
    }
  }
}

header.banner {
  position: absolute;
  width: 100%;
  z-index: 999;
  span.redline {
    position: absolute;
    height: 26px;
    top: 53px;
    max-width: none;
    background-image: url("../images/redline-3.jpg");
    @include wide {
      background-image: url("../images/redline-2.jpg");
      height: 29px;
      top: 50px;
    }
  }
  span.redline-left {
    width: calc(50% - 270px);
    left: 0;
    @include tablet {
      width: calc(50% - 360px);
    }
    @include laptop {
      width: calc(50% - 480px);
    }
    @include desktop {
      width: calc(50% - 700px);
    }
    @include hd {
      width: calc(50% - 780px);
    }
  }
  span.redline-right {
      @include mobile {
        width: calc(100% - 350px);
        left: 350px;
      }
      @include phablet {
        width: calc(50% - 80px);
        left: calc(50% + 80px);
      }
      @include tablet {
        width: calc(50% + 10px);
        left: calc(50% - 10px);
      }
      @include laptop {
        width: calc(50% + 130px);
        left: calc(50% - 130px);
      }
      @include desktop {
        width: calc(100% - 350px);
        left: 350px;
      }
      @include wide {
        width: calc(50% + 292px);
        left: calc(50% - 292px);
      }
      @include hd {
        width: calc(50% + 390px);
        left: calc(50% - 390px);
      }
  }
  span.background {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 82px;
    background-color: $white;
    z-index: 0;
    @include mobile {
      height: 67px;
    }
  }

  .container-header {
    position: relative;
    @include desktop {
      max-width: none;
    }
    @include wide {
      max-width: 1400px;
    }
    @include hd {
      max-width: 1560px;
    }
    a.brand {
      position: absolute;
      top: 17px;
      left: calc(50% - 85px);
      font-family: $logo-font;
      font-weight: bold;
      font-size: 17px;
      line-height: 16px;
      display: flex;
      text-align: center;
      width: 170px;
      @include mobile {
        display: block;
        position: absolute;
        left: 0;
        text-align: center;
        width: 100%;
      }
    }
    img.logo {
      position: absolute;
      display: block;
      left: 0;
      top: 60px;
      width: 100%;
      @include mobile {
        top: 45px;
      }
      @include wide {
        top: 40px;
      }
    }
    img.logo, a.brand {
      @include mobile {
        width: 350px;
      }
      @include wide {
        width: 408px;
      }
      @include hd {
        left: -18px;
      }
    }
    nav.nav-primary {
      padding-top: 13px;
      display: none;
      justify-content: flex-end;
      
      @include desktop {
        display: block;
        margin-left: 28%;

        > div {
          display: flex;
          justify-content: flex-end;
        }
      }

      ul.nav {
        width: 100%;
        display: flex;
        justify-content: space-between;
        li.menu-item {
          a {
            font-size: 17px;
          }
        }
        li.current-menu-item {
          a {
            color: $brand-primary;
          }
        }
        li.phone-number {
          a {
            padding: 4px 10px 6px;
            background-color: #145fa1;
            color: white;
            font-weight: bold;
          }
        }
        li.menu-item-has-children {
          &:after {
            content: "\f0d7";
            font-family: FontAwesome;
            font-size: 14px;
          }
          ul.sub-menu {
            display: none;
            position: absolute;
            background-color: #fff;
            border: 2px solid grey;
            list-style-type: none;
            padding: 5px 0 10px 0;
            transition: 0.3s;

            li {
              padding: 5px 15px;

              a {
                color: #000;
                &:hover {
                  color: $brand-primary;
                }
              }
            }
          }
          &:hover, &:focus {
            ul.sub-menu {
              display: block;
            }
          }
        }
      }
    }
  }


  button.hamburger {
    @include desktop {
      display: none;
    }
  }
}
li.menu-1-800-688-4312 {
  display: none;
  @include desktop {
    display: list-item;
  }
}
// ul.dropdown-menu.show {
//   right: 0;
// }
