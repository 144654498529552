// DON'T FORGET TO ADD THE STICKY FOOTER STUFF INTO CODE
html, body {
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  font-family: $body-font;
  color: $body-color;
  height: auto!important;
  @include smooth-fonts;
}

main.main {
  padding-left: 0;
  padding-right: 0;
  max-width: 100%!important;
}
img {
  max-width: 100%;
  height: auto;
}

h1, .h1 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h1-weight;
  font-size: $h1-size;
}

h2, .h2 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h2-weight;
  font-size: $h2-size;
}

h3, .h3 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h3-weight;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 30px;
  @include phablet {
    max-width: 190px;
  }
  @include wide {
  max-width: none;
  }
}

h4 {
  font-family: $heading-font;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 1rem;
}

p, li {
  font-family: $body-font;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
}

a {
  transition: $base-transition;
  color: $black;
  &:hover, &:focus {
    text-decoration: none;
  }
}

.jumbo-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.lSAction>a {
   background-image: url("../images/controls.png");
}

// STICKY FOOTER STUFF
// html, body {
//   height: 100%;
// }
// body {
//   display: flex;
//   flex-direction: column;
// }
// .wrap {
//   flex: 1 0 auto;
// }
// STICKY FOOTER STUFF
