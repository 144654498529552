.jumbo-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 350px;

  @include phablet {
    height: 450px;
  }

  @include laptop {
    height: auto;
  }

}
.hero-cta {
  text-align: center;
  padding-bottom: 20px;
  padding-top: 20px;
  font-family: $secondary-font;
  z-index: 1;
  background-color: #ececec;
  a.button:first-of-type {
    margin-bottom: 15px;
  }

  a.button:first-of-type {
    margin-right: 10px;
    @include mobile {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }

  @include laptop {
    margin-bottom: 60px;
    padding-top: 170px;
    text-align: center;
    background-color: transparent;
  }
}
h2.hero-txt-a, span.hero-txt-sub {
  display: block;
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
  color: $black;
  max-width: auto;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  text-shadow: none;
  margin-left: 0;

  @include laptop {
    max-width: 560px;
    color: $white;
    text-shadow: 1px 1px #000;
    text-align: left;
    font-size: 50px;
    line-height: 60px;
  }
}

span.hero-txt-sub {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 25px;
  text-align: center;

  @include laptop {
    font-size: 24px;
    text-align: left;
    line-height: 34px;
  }
}
