// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.gform_wrapper {
  ul.gform_fields li.gfield {
    padding-right: 0!important;
  }
  h3.gform_title {
    margin-top: 0;
    margin-bottom: 15px;
    // color: #fff;
  }
  ul.gform_fields {
    padding-left: 0;
    list-style-type: none;
    label.gfield_label {
      font-weight: 700;
      margin-bottom: 5px;
      // color: #fff;
    }
    .ginput_container_name {
        .name_first, .name_last {
            display: inline-block;
            float: left;
            width: 50%;

            input {
                width: 100%;
            }
            label {
                font-size: 16px;
                color: #555;
                vertical-align: top;
            }
        }
        .name_first {
            padding: 0 5px 0 0;
        }
        .name_last {
            padding: 0 0 0 5px;
        }
    }
    .ginput_container_textarea {
      textarea.large {
            height: 200px;
      }
    }
    ul.gfield_checkbox {
        list-style-type: none;
        padding: 0 0 10px;
        margin: 0;

        label {
            margin-bottom: 0;
        }
    }
    input.large, textarea.large, select.large {
      width: 100%;
      margin-bottom: 10px;
      border: transparent;
      background-color: #fff;
    }
  }
}

label {
  font-weight: 700 !important;
}

//Remove Captcha label
.g-recaptcha label {
  @extend .sr-only;
}


.ginput_stripe_creditcard input,
  #input_7_9 select,
  #input_7_9 input {
    display: block;
    width: 100%;
    border: none;
 }

 #gform_9 {
  #gform_fields_9 {
    display: flex;
    flex-wrap: wrap;
    row-gap: 32px;
    justify-content: space-between;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
    }

    li {
      margin: 5px 0;
      width: 100%;

      @include tablet {
        width: 47%;
      }

      .ginput_container {
        input {
          width: 100%;
          margin-right: 7px;
        }
      }

      .ginput_container.ginput_container_radio {
        background-color: white;
        padding: 12px 18px 6px;
        border-radius: 2px;
        border: 0.5px solid #767676;

        .gfield_radio {
          li {
            width: fit-content;
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;

            input {
              width: fit-content;
              top: -3px;
              position: relative;
            }
          }
        }
      }
    }
  }

  #gform_fields_9 #field_9_10 {
    width: auto;
    margin: 0;
  }

  .gform-footer #gform_submit_button_9 {
    margin: 0 !important;
  }
 }
 